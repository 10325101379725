<template>
  <div>
    <categorias-table seccion="proyectos"></categorias-table>
  </div>
</template>

<script>
import CategoriasTable from "@/components/categorias/table.vue";
export default {
  components: {
    CategoriasTable,
  },
};
</script>

<style></style>
